/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_helpers";
import { ApiPostInce } from "../../../../../helpers/API/ApiData";
export function AsideMenuList({ layoutProps }) {
  const sideMenu = [
    {
      name: "Dashboard",
      pathName: "/dashboard",
      svg: "/media/svg/icons/Design/Layers.svg"
    },
    {
      name: "User",
      pathName: "/user_list",
      svg: "/media/svg/icons/General/User.svg"
    },
    {
      name: "User scores",
      pathName: "/user_scores",
      svg: "/media/svg/icons/General/User.svg"
    },
    {
      name: "Prizes",
      pathName: "/prize_list",
      svg: "/media/svg/icons/Shopping/Gift.svg"
    },
    {
      name: "Workout Playlist",
      pathName: "/video_playlist",
      svg: "/assets/media/svg/icons/Media/Movie-lane1.svg"
    },
    {
      name: "Workout Type",
      pathName: "/workout_type",
      svg: "/assets/media/svg/icons/Media/Movie-lane1.svg"
    },
    {
      name: "Focus Area",
      pathName: "/focus_area",
      svg: "/assets/media/svg/icons/Media/Movie-lane1.svg"
    },
    {
      name: "Workout List",
      pathName: "/video_list",
      svg: "/assets/media/svg/icons/Media/Youtube.svg"
    },
    {
      name: "Equipment",
      pathName: "/equipment",
      svg: "/media/svg/icons/General/Thunder.svg"
    },
    {
      name: "Plan",
      pathName: "/plan",
      svg: "/media/svg/icons/General/Thunder.svg"
    },
    {
      name: "Notifications",
      pathName: "/notification",
      svg: "/media/svg/icons/General/Notifications1.svg"
    },
    {
      name: "Nutrition",
      pathName: "/nutrition",
      svg: "/media/svg/icons/Food/Orange.svg"
    },
    {
      name: "Dietary",
      pathName: "/dietary",
      svg: "/media/svg/icons/Shopping/Cart2.svg"
    },
    {
      name: "Meal",
      pathName: "/meal",
      svg: "/media/svg/icons/Food/Miso-soup.svg"
    },
    {
      name: "Recipe Plan",
      pathName: "/recipe-plan",
      svg: "/assets/media/svg/icons/Cooking/Cooking-pot.svg"
    },
    {
      name: "Recipe",
      pathName: "/recipe",
      svg: "/media/svg/icons/Communication/Clipboard-list.svg"
    },
  ]
  const [accountData, setaccountData] = useState({});
  const [type, setType] = useState();
  const [open1, setOpen1] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const Id = JSON.parse(localStorage.getItem("userinfo"));
    setType(Id?.role);
  }, []);

  const getMenuItemActive = (url, hasSubmenu = false) => {
    return activeClass(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };

  const activeClass = (location, url) => {
    let current = location?.pathname
    if (!current || !url) {
      return false
    }
    if (current === url) {
      return true
    }
    return false
  }

  const handleonChange = (e) => {
    console.log(e);
    let { name, value } = e.target;
    if (name === "canShareWithDifferent") {
      accountData[name] = e.target.checked;
      setaccountData({ ...accountData });
    } else if (name === "canShareWithSame") {
      accountData[name] = e.target.checked;
      setaccountData({ ...accountData });
    } else if (name === "requestSignature") {
      accountData[name] = e.target.checked;
      setaccountData({ ...accountData });
    } else {
      setaccountData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleClickOpen1 = () => {
    console.log("jjjjjjjjj");
    setOpen1(true);
  };

  const submitfeed = () => {
    if (accountData.message) {
      console.log(accountData);
      const Id3 = JSON.parse(localStorage.getItem("token"));
      let body = {
        message: accountData.message,
      };
      ApiPostInce("feedBack", Id3, body)
        // ApiPost("get-user-details-by-id",data)
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            console.log(res);
            alert(res.message);
            handleClose1();
          } else {
          }
        });
    } else {
      alert("please enter Message");
    }
  };

  const handleClose1 = () => {
    setOpen1(false);
  };

  const signout = () => {
    localStorage.clear();
    window.location.href = "/auth/login";
  };

  return (
    <ul className={`menu-nav ${layoutProps.ulClasses}`}>
      {sideMenu.map((item) => {
        return (
          <li
            key={item.pathName}
            className={`menu-item ${getMenuItemActive(item.pathName, false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to={item.pathName}>
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl(item.svg)} />
              </span>
              <span className="menu-text">{item.name}</span>
            </NavLink>
          </li>
        )
      })}
      <li
        className={`menu-item ${getMenuItemActive("", false)}`}
        aria-haspopup="true"
      >
        <div className="menu-link" onClick={signout}>
          <span className="svg-icon menu-icon">
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Navigation/Sign-out.svg")}
            />
          </span>
          <span className="menu-text">Sign Out</span>
        </div>
      </li>
    </ul>
  );
}




// <ul className={`menu-nav ${layoutProps.ulClasses}`}>
//       {/*begin::1 Level*/}
//       <li
//         className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
//         aria-haspopup="true"
//       >
//         <NavLink className="menu-link" to="/dashboard">
//           <span className="svg-icon menu-icon">
//             <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
//           </span>
//           <span className="menu-text">Dashboard</span>
//         </NavLink>
//       </li>
//       <li
//         className={`menu-item ${getMenuItemActive("/user_list", false)}`}
//         aria-haspopup="true"
//       >
//         <NavLink className="menu-link" to="/user_list">
//           <span className="svg-icon menu-icon">
//             <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
//           </span>
//           <span className="menu-text">User</span>
//         </NavLink>
//       </li>
//       <li
//         className={`menu-item ${getMenuItemActive("/video_playlist", false)}`}
//         aria-haspopup="true"
//       >
//         <NavLink className="menu-link" to="/video_playlist">
//           <span className="svg-icon menu-icon">
//             <SVG
//               src={toAbsoluteUrl(
//                 "/assets/media/svg/icons/Media/Movie-lane1.svg"
//               )}
//             />
//           </span>
//           <span className="menu-text">Workout Playlist</span>
//         </NavLink>
//       </li>
//       <li
//         className={`menu-item ${getMenuItemActive("/workout_type", false)}`}
//         aria-haspopup="true"
//       >
//         <NavLink className="menu-link" to="/workout_type">
//           <span className="svg-icon menu-icon">
//             <SVG
//               src={toAbsoluteUrl(
//                 "/assets/media/svg/icons/Media/Movie-lane1.svg"
//               )}
//             />
//           </span>
//           <span className="menu-text">Workout Type</span>
//         </NavLink>
//       </li>
//       <li
//         className={`menu-item ${getMenuItemActive("/focus_area", false)}`}
//         aria-haspopup="true"
//       >
//         <NavLink className="menu-link" to="/focus_area">
//           <span className="svg-icon menu-icon">
//             <SVG
//               src={toAbsoluteUrl(
//                 "/assets/media/svg/icons/Media/Movie-lane1.svg"
//               )}
//             />
//           </span>
//           <span className="menu-text">Focus Area</span>
//         </NavLink>
//       </li>
//       <li
//         className={`menu-item ${getMenuItemActive("/video_list", false)}`}
//         aria-haspopup="true"
//       >
//         <NavLink className="menu-link" to="/video_list">
//           <span className="svg-icon menu-icon">
//             <SVG
//               src={toAbsoluteUrl("/assets/media/svg/icons/Media/Youtube.svg")}
//             />
//           </span>
//           <span className="menu-text">Workout List</span>
//         </NavLink>
//       </li>
//       {/* <li
//         className={`menu-item ${getMenuItemActive("/workout", false)}`}
//         aria-haspopup="true"
//       >
//         <NavLink className="menu-link" to="/workout">
//           <span className="svg-icon menu-icon">
//             <SVG
//               src={toAbsoluteUrl("/media/svg/icons/Clothes/Sneakers.svg")}
//             />
//           </span>
//           <span className="menu-text">Workout</span>
//         </NavLink>
//       </li> */}
//       <li
//         className={`menu-item ${getMenuItemActive("/equipment", false)}`}
//         aria-haspopup="true"
//       >
//         <NavLink className="menu-link" to="/equipment">
//           <span className="svg-icon menu-icon">
//             <SVG
//               src={toAbsoluteUrl("/media/svg/icons/General/Thunder.svg")}
//             />
//           </span>
//           <span className="menu-text">Equipment</span>
//         </NavLink>
//       </li>
//       <li
//         className={`menu-item ${getMenuItemActive("/plan", false)}`}
//         aria-haspopup="true"
//       >
//         <NavLink className="menu-link" to="/plan">
//           <span className="svg-icon menu-icon">
//             <SVG
//               src={toAbsoluteUrl("/media/svg/icons/General/Thunder.svg")}
//             />
//           </span>
//           <span className="menu-text">Plan</span>
//         </NavLink>
//       </li>
//       <li
//         className={`menu-item ${getMenuItemActive("/notification", false)}`}
//         aria-haspopup="true"
//       >
//         <NavLink className="menu-link" to="/notification">
//           <span className="svg-icon menu-icon">
//             <SVG
//               src={toAbsoluteUrl(
//                 "/media/svg/icons/General/Notifications1.svg"
//               )}
//             />
//           </span>
//           <span className="menu-text">Notifications</span>
//         </NavLink>
//       </li>

//       <li
//         className={`menu-item ${getMenuItemActive("/nutrition", false)}`}
//         aria-haspopup="true"
//       >
//         <NavLink className="menu-link" to="/nutrition">
//           <span className="svg-icon menu-icon">
//             <SVG src={toAbsoluteUrl("/media/svg/icons/Food/Orange.svg")} />
//           </span>
//           <span className="menu-text">Nutrition</span>
//         </NavLink>
//       </li>

//       <li
//         className={`menu-item ${getMenuItemActive("/dietary", false)}`}
//         aria-haspopup="true"
//       >
//         <NavLink className="menu-link" to="/dietary">
//           <span className="svg-icon menu-icon">
//             <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Cart2.svg")} />
//           </span>
//           <span className="menu-text">Dietary</span>
//         </NavLink>
//       </li>

//       <li
//         className={`menu-item ${getMenuItemActive("/meal", false)}`}
//         aria-haspopup="true"
//       >
//         <NavLink className="menu-link" to="/meal">
//           <span className="svg-icon menu-icon">
//             <SVG src={toAbsoluteUrl("/media/svg/icons/Food/Miso-soup.svg")} />
//           </span>
//           <span className="menu-text">Meal</span>
//         </NavLink>
//       </li>

//       <li
//         className={`menu-item ${getMenuItemActive("/recipe-plan", false)}`}
//         aria-haspopup="true"
//       >
//         <NavLink className="menu-link" to="/recipe-plan">
//           <span className="svg-icon menu-icon">
//             <SVG
//               src={toAbsoluteUrl(
//                 "/assets/media/svg/icons/Cooking/Cooking-pot.svg"
//               )}
//             />
//           </span>
//           <span className="menu-text">Recipe Plan</span>
//         </NavLink>
//       </li>
//       <li
//         className={`menu-item ${getMenuItemActive("/recipe", false)}`}
//         aria-haspopup="true"
//       >
//         <NavLink className="menu-link" to="/recipe">
//           <span className="svg-icon menu-icon">
//             <SVG
//               src={toAbsoluteUrl(
//                 "/media/svg/icons/Communication/Clipboard-list.svg"
//               )}
//             />
//           </span>
//           <span className="menu-text">Recipe</span>
//         </NavLink>
//       </li>
//       {/* <li
//           className={`menu-item menu-item-submenu ${getMenuItemActive(
//             "/error",
//             true
//           )}`}
//           aria-haspopup="true"
//           data-menu-toggle="hover"
//         >
//           <NavLink className="menu-link menu-toggle" to="/error">
//             <span className="svg-icon menu-icon">
//               <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Earth.svg")} />
//             </span>
//             <span className="menu-text">Manage Categories</span>
//             <i className="menu-arrow mt-2" />
//           </NavLink>
//           <div className="menu-submenu ">
//             <i className="menu-arrow" />
//             <ul className="menu-subnav">
//               <li
//                 className="menu-item  menu-item-parent"
//                 aria-haspopup="true"
//               >
//                 <span className="menu-link">
//                   <span className="menu-text">Manage Categories</span>
//                 </span>
//               </li>
//               <li
//                 className={`menu-item ${getMenuItemActive("/category_List")}`}
//                 aria-haspopup="true"
//               >
//                 <NavLink className="menu-link" to="/category_List">
//                   <i className="menu-bullet menu-bullet-dot">
//                     <span />
//                   </i>
//                   <span className="menu-text"> Main Categories</span>
//                 </NavLink>
//               </li>
//               <li
//                 className={`menu-item ${getMenuItemActive(
//                   "/subCategory_List"
//                 )}`}
//                 aria-haspopup="true"
//               >
//                 <NavLink className="menu-link" to="/subCategory_List">
//                   <i className="menu-bullet menu-bullet-dot">
//                     <span />
//                   </i>
//                   <span className="menu-text"> Sub Categories</span>
//                 </NavLink>
//               </li>
//               <li
//                 className={`menu-item ${getMenuItemActive("/software_List")}`}
//                 aria-haspopup="true"
//               >
//                 <NavLink className="menu-link" to="/software_List">
//                   <i className="menu-bullet menu-bullet-dot">
//                     <span />
//                   </i>
//                   <span className="menu-text"> Software</span>
//                 </NavLink>
//               </li>
//             </ul>
//           </div>
//         </li> */}
//       <li
//         className={`menu-item ${getMenuItemActive("", false)}`}
//         aria-haspopup="true"
//       >
//         <div className="menu-link" onClick={() => signout()}>
//           <span className="svg-icon menu-icon">
//             <SVG
//               src={toAbsoluteUrl("/media/svg/icons/Navigation/Sign-out.svg")}
//             />
//           </span>
//           <span className="menu-text">Sign Out</span>
//         </div>
//       </li>
//     </ul>
