import React, { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { ApiDelete, ApiPost, ApiPut, ApiUpload, ApiGet } from "../../../helpers/API/ApiData";
import { ErrorToast, SuccessToast } from "../../../helpers/Toast";
import moment from "moment";
import { Form, Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import thumbDefault from "../../../media/thumbDefault.jpg";
import { MdCancel } from "react-icons/md";

function PrizeList() {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);
  const [loading, setLoading] = useState(false);
  const [newData, setNewData] = useState({
    name: "",
    image: "",
    experienceLevel: "",
    description: "",
  });
  const columns = [
    {
      dataField: "image",
      text: "Image",
      sort: true,
      formatter: (cell, row) => {
        return (
          <>
            <div className="d-flex align-items-center">
              <div className="symbol symbol-50px symbol-light mr-4">
                <img src={row?.image ? row?.image : thumbDefault} className="img-fluid" onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = "/media/img/fitnessLogo.png";
                }} />
              </div>
            </div>
          </>
        );
      },
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
      formatter: (cell, row) => {
        return <div>{cell || "-"}</div>;
      },
    },
    {
      dataField: "experienceLevel",
      text: "Experience Level",
      sort: true,
      formatter: (cell, row) => {
        return <div>{cell || "-"}</div>;
      },
    },
    {
      dataField: "description",
      text: "Description",
      sort: true,
      formatter: (cell, row) => {
        return <div>{cell || "-"}</div>;
      },
    },
    {
      dataField: "action",
      text: "",
      sort: true,
      formatter: (cell, row) => {
        return (
          <>
            <a
              title="Edit customer"
              className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
              onClick={editBtnClick.bind(null, row)}
            >
              <span className="svg-icon svg-icon-md svg-icon-primary">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")} />
              </span>
            </a>

            <a
              title="Delete customer"
              className="btn btn-icon btn-light btn-hover-danger btn-sm"
              onClick={() => Swal.fire({
                text: `Are you sure you want to delete the prize "${row?.name}"?`,
                icon: "warning",
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: `Yes, Delete`,
                confirmButtonColor: "#D72852",
                cancelButtonColor: "transparent",
                cancelButtonText: "No, Cancel",
              }).then((res) => res.isConfirmed && deletePrize(row?.id))}
            >
              <span className="svg-icon svg-icon-md svg-icon-danger">
                <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
              </span>
            </a>
          </>
        );
      },
    },
  ];
  const handleChange = (e) => setNewData({ ...newData, [e.target.name]: e.target.value });
  const editBtnClick = (row) => {
    console.log("row", row);
    setIsEdit(true);
    setModal(true);
    setNewData({
      id: row?.id,
      name: row?.name,
      image: row?.image,
      experienceLevel: row?.experienceLevel,
      description: row?.description,
    });
  };
  const deletePrize = async (Id) => {
    await ApiDelete(`/prize/${Id}`)
      .then((res) => {
        fetchData(searchTerm);
        Swal.fire({
          text: "The prize has been deleted successfully.",
          icon: "success",
          confirmButtonText: "Ok, Got it!",
          confirmButtonColor: "#338DE6",
        });
      })
      .catch((err) => ErrorToast(err?.message));
  };
  const updatePrize = async () => {
    setLoading(true);
    let body = {
      name: newData?.name,
      image: newData?.image,
      experienceLevel: newData?.experienceLevel,
      description: newData?.description,
    };
    await ApiPut(`/prize/${newData?.id}`, body)
      .then(async (res) => {
        SuccessToast(res?.data?.message);
        setModal(false);
        const newResults = await fetchData(searchTerm);
        setTimeout(() => {
          // reset search if current search is not matching new value
          if (!newResults.find(i => i.id === newData.id)) {
            setSearchTerm('');
          }
        });
      })
      .catch((err) => {
        ErrorToast(err?.message);
      })
      .finally(() => setLoading(false));
;
  };
  const handleSubmit = async () => {
    setLoading(true);
    let body = {
      name: newData?.name,
      image: newData?.image,
      experienceLevel: newData?.experienceLevel,
      description: newData?.description,
    };
    console.log("body", body);
    await ApiPost("/prize", body)
      .then(async (res) => {
        SuccessToast(res?.data?.message);
        setModal(false);
        const newResults = await fetchData(searchTerm);
        // reset search if current search is not matching new value
        if (!newResults.find(i => i.name === newData.name)) {
          setSearchTerm('');
        }
      })
      .catch((err) => {
        ErrorToast(err?.message);
      })
      .finally(() => setLoading(false));
  };
  const imageChange = async (e) => {
    let file = e.target.files[0];
    const isValidImageType = ["image/jpeg", "image/png", "image/gif"].includes(file.type);
    if (!isValidImageType) {
      ErrorToast("Invalid file type. Please select a valid image.");
      document.getElementById("url").value = null;
      return;
    }

    setLoading(true);
    let fileURL = URL.createObjectURL(file);
    file.fileURL = fileURL;
    let formData = new FormData();
    formData.append("image", file);
    await ApiUpload("upload/compress_image/profile_image", formData)
      .then((res) => {
        setNewData({ ...newData, image: res?.data?.publicUrl[0] });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const fetchData = async ({ search }) => {
    const params = new URLSearchParams({ search });
    return ApiGet(`/prize?${params.toString()}`)
      .then((res) => {
        console.log("resData", res?.data);
        const resultData = res.data.data.items;
        setData(resultData);
        return resultData;
      })
      .catch((err) => ErrorToast(err?.message));
  };

  useEffect(() => {
    fetchData({ search: debouncedSearchTerm });
  }, [debouncedSearchTerm]);
  return (
    <>
      <div className="d-flex justify-content-between mb-4">
        <div className="title">
          <div className="fs-20px fw-bolder">Prize Lists</div>
          <div>
            <span
              role="button"
              onClick={() => history.push("/dashboard")}
              className="text-hover-info text-muted"
            >
              Home
            </span>{" "}
            -{" "}
            <span className="text-muted" role="button">
              {" "}
              Prize list
            </span>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column flex-column-fluid h-100" id="kt_content">
        <div class="card card-custom">
          <div class="card-header flex-wrap border-0 pt-6 pb-0 w-100">
            <div class="card-title ">
              <div class="input-icon">
                <input
                  type="text"
                  class="form-control bg-light"
                  name="searchText"
                  placeholder="Search by Name"
                  value={searchTerm}
                  onChange={(event) => setSearchTerm(event.target.value)}
                  id="kt_datatable_search_query"
                />
                <span>
                  <i class="flaticon2-search-1 text-muted"></i>
                </span>
              </div>
            </div>

            <div class="card-toolbar">
              <a
                class="btn btn-color font-weight-bolder btn-sm"
                onClick={() => {
                  setIsEdit(false);
                  setModal(true);
                  setNewData({
                    title: "",
                    description: "",
                    image: "",
                    experienceLevel: "",
                  });
                }}
              >
                Add Prize
              </a>
            </div>
          </div>
          <div className="card-body mb-5">
            {/* <div class="row align-items-center">
              <div class="col-lg-12 col-xl-12">
                <div class="row align-items-center">
                  <div class="col-md-3 my-2">
                    <div class="input-icon">
                      <input
                        type="text"
                        class="form-control bg-light"
                        name="searchText"
                        placeholder="Search by Name"
                        value={searching}
                        onChange={(e) => handlesearch(e)}
                        id="kt_datatable_search_query"
                      />
                      <span>
                        <i class="flaticon2-search-1 text-muted"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div class="row align-items-center">
              <div class="col-lg-12 col-xl-12">
                <div class="row align-items-center">
                  <div className="col-md-3 my-2">
                    <Dropdown
                      onSelect={handleonchnagestatus}
                      defaultValue="request"
                    >
                      <Dropdown.Toggle
                        id="dropdown-basic"
                        className="text-capitalize"
                      >
                        {state1}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item eventKey="approve">
                          Apporved
                        </Dropdown.Item>
                        <Dropdown.Item eventKey="request">
                          Pending
                        </Dropdown.Item>
                        <Dropdown.Item eventKey="reject">
                          Rejected
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className="col-md-3 my-2">
                    <DateRangePicker onApply={apply} onCancel={cancel}>
                      <input
                        type="text"
                        className="form-control bg-light"
                        value={valll}
                        placeholder="Select Date Range"
                      />
                    </DateRangePicker>
                  </div>
                  <div className="col-md-3 my-2">
                    <Dropdown onSelect={handleonchnagestatus2}>
                      <Dropdown.Toggle id="dropdown-basic">
                        {state2 === true ? "Active" : "Inactive"}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item eventKey="Active">Active</Dropdown.Item>
                        <Dropdown.Item eventKey="Inactive">
                          Inactive
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </div> */}
            <BootstrapTable
              wrapperClasses="table-responsive"
              bordered={false}
              classes="table table-head-custom table-vertical-center overflow-hidden"
              bootstrap4
              keyField="id"
              data={data}
              columns={columns}
              noDataIndication={() => (
                <div className="alert alert-secondary text-center fw-bold" role="alert">
                  {searchTerm ? 'No results.' : 'No prizes have been added yet.'}
                </div>
              )}
            />
          </div>
        </div>
      </div>
      <Modal show={modal} onHide={() => setModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>{!isEdit ? " Add Prize" : "Prize Edit Details"} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <Form.Group md="12">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Name"
                label="Name"
                id="name"
                required
                name="name"
                onChange={handleChange}
                value={newData?.name}
              />
              <span className="errorInput">{/* {newData.name?.length > 0 ? "" : errors["name"]} */}</span>
            </Form.Group>
            <Form.Group md="12">
              <Form.Label>Experience Level</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter Experience Level"
                label="Experience Level"
                id="experienceLevel"
                required
                name="experienceLevel"
                onChange={handleChange}
                value={newData?.experienceLevel}
              />
              <span className="errorInput">{/* {newData.experienceLevel?.length > 0 ? "" : errors["experienceLevel"]} */}</span>
            </Form.Group>
            <Form.Group md="12">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Enter Description"
                label="Description"
                id="description"
                required
                name="description"
                onChange={handleChange}
                value={newData?.description}
              />
              <span className="errorInput">{/* {newData.description?.length > 0 ? "" : errors["description"]} */}</span>
            </Form.Group>

            <Form.Group>
              <Form.Label>Thumbnail URL</Form.Label>
              <Form.Control
                type="file"
                label="url"
                id="url"
                required
                className="form-control"
                name="url"
                accept=".pdf, .png, .jpg, .jpeg, .gif"
                onChange={(e) => imageChange(e)}
              />
              <span className="errorInput">{/* {newData.url?.length > 0 ? "" : errors["url"]} */}</span>
              {newData?.image && (
                <div className="position-relative d-flex justify-items-start align-items-start flex-nowrap mt-3">
                  <img
                    src={newData?.image}
                    alt="nutrition image"
                    style={{ width: "100px", height: "100px" }}
                    className="rounded-3"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = "/media/img/fitnessLogo.png";
                    }}
                  />
                  <span
                    class="translate-middle mt-1"
                    onClick={() => {
                      setNewData({ ...newData, image: "" });
                      document.getElementById("url").value = null;
                    }}
                  >
                    <MdCancel color="red" fontSize={20} />
                  </span>
                </div>
              )}
            </Form.Group>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {isEdit ? (
            <button className="btn btn-color" onClick={updatePrize} disabled={loading}>
              {loading ? "Loading" : "Update"}
            </button>
          ) : (
            <button className="btn btn-color" onClick={handleSubmit} disabled={loading}>
              {loading ? "Loading" : "Submit"}
            </button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default PrizeList;

