import React, { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { ApiPost } from "../../../helpers/API/ApiData";
import { ErrorToast } from "../../../helpers/Toast";
import Pagination from "@material-ui/lab/Pagination";

function UserScores() {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");

  const columns = [
    {
      dataField: "totalPoints",
      text: "Points",
      sort: true,
      formatter: (cell, row) => {
        return <div>{cell}</div>;
      },
    },
    {
      dataField: "experienceLevel",
      text: "Level",
      sort: true,
      formatter: (cell, row) => {
        return <div>{cell}</div>;
      },
    },
    {
      dataField: "first_name",
      text: "Name",
      sort: true,
      formatter: (cell, row) => {
        return <div>{cell ? `${row?.first_name} ${row?.last_name}` : "-"}</div>;
      },
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      formatter: (cell, row) => {
        return <div>{cell ? cell : "-"}</div>;
      },
    },
  ];
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");

  useEffect(() => {
    const handler = setTimeout(() => {
      setCurrentPage(1);
      setDebouncedSearchTerm(searchTerm);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  const fetchData = async ({ page, limit, search }) => {
    let body = {
      page,
      limit,
      search,
    };
    await ApiPost("/user/scores", body)
      .then((res) => {
        const output = res?.data?.data?.user_data?.filter((v, i) => {
          if (
            v?.email === "email@address.com" ||
            v?.email === "admin@gmail.com"
          ) {
            return;
          } else {
            return v;
          }
        });
        // setData(res?.data?.data?.user_data);
        setData(output);
        setTotalPages(res?.data?.data?.state?.page_limit);
      })
      .catch((err) => ErrorToast(err?.message));
  };

  useEffect(() => {
    fetchData({ page: currentPage, limit: 20, search: debouncedSearchTerm });
  }, [currentPage, debouncedSearchTerm]);

  return (
    <>
      <div className="d-flex justify-content-between mb-4">
        <div className="title">
          <div className="fs-20px fw-bolder">User scores</div>
          <div>
            <span
              role="button"
              onClick={() => history.push("/dashboard")}
              className="text-hover-info text-muted"
            >
              Home
            </span>{" "}
            -{" "}
            <span className="text-muted" role="button">
              {" "}
              User scores
            </span>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column flex-column-fluid h-100" id="kt_content">
        <ToastContainer position="top-right" />
        <div class="card card-custom">
          <div className="card-body mb-5">
            <div class="row align-items-center">
              <div class="col-lg-12 col-xl-12">
                <div class="row align-items-center">
                  <div class="col-md-4 my-2">
                    <div class="input-icon">
                      <input
                        type="text"
                        class="form-control bg-light"
                        name="searchText"
                        placeholder="Search by Name or Email"
                        value={searchTerm}
                        onChange={(event) => {
                          setSearchTerm(event.target.value);
                        }}
                        id="kt_datatable_search_query"
                      />
                      <span>
                        <i class="flaticon2-search-1 text-muted"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <BootstrapTable
              wrapperClasses="table-responsive"
              bordered={false}
              rowClasses="userRow"
              classes="table table-head-custom table-vertical-center overflow-hidden"
              bootstrap4
              keyField="_id"
              // selectRow={selectRow}
              data={data}
              columns={columns}
              // pagination={paginationFactory(options)}
              // defaultSorted={defaultSorted}
              noDataIndication={() => (
                <div className="alert alert-secondary text-center fw-bold" role="alert">
                  {searchTerm ? 'No results.' : 'No users gained any points yet.'}
                </div>
              )}
              // filter={filterFactory()}
              // headerClasses="header-class"
            />
            <div class="d-flex justify-content-between  pt-10">
              <div className="my-2">
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  onChange={(e, i) => setCurrentPage(i)}
                  variant="outlined"
                  shape="rounded"
                  className="pagination_"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserScores;

